import { parsePhoneNumber } from "libphonenumber-js";

export const required = (type = "string") => ({
  type,
  required: true,
  message: "Поле не может быть пустым",
  trigger: "change",
});

export const length = (min, max, trigger = "change") => ({
  validator: (rules, value, callback) => {
    let error = null;

    if (typeof value !== "string" && min === 0) {
      callback();
      return;
    }

    const arr = value.split(" | ");

    if (arr[0] === "") {
      callback();
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].length < min || arr[i].length > max) error = true;
    }

    if (error !== null) callback(new Error());
    else callback();
  },
  message: `Длина должна быть от ${min} до ${max}`,
  trigger,
});

export const validatorSpace = {
  validator: (rules, value, callback) => {
    if (!/\S/.test(value)) callback(new Error());
    else callback();
  },
  message: "Поле не может состоять из пробелов",
  trigger: "change",
};

export const validatorIp = {
  validator: (rules, value, callback) => {
    let regex = new RegExp(
      "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"
    );
    if (!regex.test(value || 0)) callback(new Error());
    else callback();
  },
  message: "Введите валидный IP адрес",
  trigger: "change",
};

export const validatorUrl = {
  validator: (rules, value, callback) => {
    if (value) {
      const regex = new RegExp(
        "^(?:http(s)?:\\/\\/)[\\w.-]+(?:[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"
      );

      if (!regex.test(value)) callback(new Error());
    }

    callback();
  },
  message: "Введите валидный URL адрес",
  trigger: "change",
};

export const validatorLatine = {
  validator: (rules, value, callback) => {
    if (/[^a-z0-9-_]/g.test(value)) callback(new Error());
    else callback();
  },
  message: "Ввести можно только латиницу в нижнем регистре и символы - , _",
  trigger: "change",
};

export const validatorAllLatine = {
  validator: (rules, value, callback) => {
    if (/[^A-Za-z0-9-_]/g.test(value)) callback(new Error());
    else callback();
  },
  message: "Ввести можно только латиницу и символы - или _",
  trigger: "change",
};

export const validatorNumber = {
  validator: (rules, value, callback) => {
    if (/[^0-9]/g.test(value)) callback(new Error());
    else callback();
  },
  message: "Ввести можно только целые числа",
  trigger: "change",
};

export const validatorResolution = {
  validator: (rules, value, callback) => {
    if (value.length === 0) callback();
    if (!/^((?!(0))[0-9]{3,})x((?!(0))[0-9]{3,})/g.test(value)) callback(new Error());
    else callback();
  },
  message: 'Введите правильное разрешение (например: "640х480")',
  trigger: "change",
};

export const validatorPhoneNumber = {
  validator: (rules, value, callback) => {
    value = value.toString();
    if (value.length === 0) callback();
    try {
      let phoneNumber = parsePhoneNumber(value, "RU");
      if (phoneNumber.isValid() === true) callback();
      else callback(new Error());
    } catch (error) {
      callback(new Error());
    }
  },
  message: "Неверный формат номера телефона",
  trigger: "change",
};

export const validatorNumberMin = (min) => ({
  validator: (rules, value, callback) => {
    if (typeof value !== "number" || value < min) {
      throw new Error();
    }
    callback();
  },
  message: "Значение должно быть больше",
  trigger: "change",
});

export const validatorNumberMax = (max) => ({
  validator: (rules, value, callback) => {
    if (typeof value !== "number" || value > max) {
      throw new Error();
    }
    callback();
  },
  message: "Значение должно быть меньше",
  trigger: "change",
});

export const validatorNumberInRange = (min, max) => ({
  validator: (rules, value, callback) => {
    if (typeof value !== "number") {
      throw new Error();
    }
    if (value < min || value > max) {
      throw new Error();
    }
    callback();
  },
  message: "Значение не попадает в диапазон разрешенных",
  trigger: "change",
});

export default {
  required,
  length,
  validatorSpace,
  validatorIp,
  validatorNumber,
  validatorResolution,
  validatorNumberMin,
  validatorNumberMax,
  validatorNumberInRange,
};
